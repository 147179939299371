<template>
  <div class="index">
    <div class="heard" id="home">
      <img src="../assets/img/home_ic.png" alt="" class="homes" @click="open" />
      <img src="../assets/img/logo.png" alt="" class="logo" />
    </div>
    <div class="navigation" :style="openOrClose">
      <img src="../assets/img/home_ic_off.png" alt="" @click="Close" />
      <div class="list">
        <ul id="nav">
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="go(item.path, $event, index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <!--首页开始-->
    <div class="homePage">
      <div class="h_left">
        <div class="text" :style="text_top">
          <span class="bold"
            >To provide customers with personalized product solutions</span
          >
          <span class="min_size"
            >We are deeply engaged in the research and development of new
            products and new technologies, and constantly adapt to the
            cutting-edge changes in the market, so as to provide customers with
            better services</span
          >
          <div class="btn">
            <span @click="go('contact')">Contact us</span>
          </div>
        </div>
      </div>
      <div class="h_right">
        <img src="../assets/img/home_img1.png" alt="" />
      </div>
    </div>
    <!--首页结束-->
    <!--实时交互？不知道是什么鬼-->
    <div class="realTiem" id="interactive">
      <div class="r_left_img">
        <img src="../assets/img/real_time1.jpg" alt="" :style="iImgStyle" />
        <!-- :style="iImgStyle" -->
      </div>
      <div class="r_right_text">
        <div class="r_text">
          <div class="text" :style="iTextStyle">
            <h1>Fast real-time interaction</h1>
            <ul>
              <li>
                <span class="title">Voice call</span>
                <span class="span">
                  Support 1 on 1 chat, group chat real-time voice call Used in
                  voice chat room, voice social interaction and other
                  interactive scenes
                </span>
              </li>
              <li>
                <span class="title">Video call</span>
                <span class="span">
                  Support 1 to 1, many to many real-time video call It is used
                  in interactive scenes such as video social interaction and
                  interactive classroom
                </span>
              </li>
              <li>
                <span class="title">Interactive live</span>
                <span class="span">
                  Real-time audio and video communication between users. Users
                  can interact in the chat room.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--实时交互结束-->
    <!--第三模块开始-->
    <div class="quality" id="quality">
      <div class="q_left">
        <div class="text" :style="qTextStyle">
          <span class="title">Ultra-low delay and ultra-high quality</span>
          <span class="content">
            In the global deployment of data centers, software-defined network
            algorithms cover nearly 200 countries and regions around the world,
            millisecond delay compared to the native network, transmission
            quality improved 10 times
          </span>
        </div>
      </div>
      <div class="q_right">
        <img src="../assets/img/home_img2.png" alt="" :style="qImgStyle" />
      </div>
    </div>
    <!--第三模块结束-->
    <!--用户评价开始-->
    <div class="evaluation" id="evaluation">
      <img class="topImg" src="../assets/img/center_img.png" alt="" />
      <div class="content">
        <div class="neirong" :style="evalTextStyle">
          <span class="title">—— &nbsp; &nbsp;Customer evaluation</span>
          <img class="titleImg" src="../assets/img/home_icon.png" alt="" />
          <div class="sildeText">
            <img
              src="../assets/img/left.png"
              alt=""
              class="s left"
              @click.prevent="btnLeft()"
            />
            <div class="s_text">
              <ul class="s_ul" id="s_ul">
                <li>
                  <span class="s span"
                    >Excellent experience, making the calling process simple and
                    pleasant</span
                  >
                </li>
                <li>
                  <span class="s span"
                    >Superior performance to guarantee your business</span
                  >
                </li>
              </ul>
            </div>
            <img
              src="../assets/img/right.png"
              alt="s right"
              @click="btnRight()"
            />
          </div>
        </div>
      </div>
    </div>
    <!--用户评价结束-->
    <!--联系我们开始-->
    <div class="Contact">
      <img class="img" src="../assets/img/home_img3.png" alt="" />
      <div class="setInfo" id="contact">
        <div class="neirong" :style="inputStyle">
          <span class="title">—— &nbsp; &nbsp;Contact us</span>
          <span class="text"
            >Build a real-time Internet, popularize real-time audio and video
            interaction, and make it as ubiquitous as air and water.</span
          >
          <div class="sumbit">
            <input type="text" placeholder="Name" v-model="Name" />
            <input type="text" placeholder="Email" v-model="Email" /><br />
            <input type="text" placeholder="Phone" v-model="Phone" />
            <input type="text" placeholder="Service" v-model="Service" />
            <span class="tijiao" @click="sub">Submit</span>
          </div>
        </div>
        <div class="tips">
          <span :style="dis">{{ res }}</span>
        </div>
        <hr />
      </div>
    </div>
    <!--联系我们结束-->
    <!--底部-->
    <div class="bottom">
      <img src="@/assets/img/logo2.png" alt="" class="logo" />
      <!-- <span
        >Blagol Technology Co., Limited<br />Rooms 1-9, 12/F, Hollywood Plaza,
        610 Nathan Road,<br />Mongkok, Kowloon, Hong Kong.<br />waha2020@163.com</span
      > -->
      <span
        >Blagol Technology Co., Limited<br />
        RM502C, 5/F, HO KING COMM CTR, 2-16 FAYUEN ST, MONGKOK KOWLOON, HONG
        KONG
        <br />
        +85295640512<br />
        contact@blagol.com</span
      >
      <img src="@/assets/img/btn.png" alt="" class="btn" @click="toTop" />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      openOrClose: "left:-280px",
      text_top: "",
      iImgStyle: "",
      iTextStyle: "",
      qTextStyle: "",
      qImgStyle: "",
      evalTextStyle: "",
      inputStyle: "",
      scroll: "",
      dis: "",
      gotop: false,
      Num: 1,
      rListNum: 1200,
      navList: [
        {
          path: "",
          name: "Homepage",
        },
        {
          path: "interactive",
          name: "Real-time interactive",
        },
        {
          path: "quality",
          name: "High quality",
        },
        {
          path: "evaluation",
          name: "Customer evaluation",
        },
        {
          path: "contact",
          name: "Contact us",
        },
      ],
      selectIndex: 0,
      Name: "",
      Email: "",
      Phone: "",
      Service: "",
      res: "",
    };
  },
  watch: {
    scroll(val) {
      // 监听滚动条位置显示动画效果
      if (val >= 800) {
        this.iImgStyle = "width:100%;opacity:100";
        setTimeout(() => {
          this.iTextStyle = "margin-top:200px;opacity:100";
        }, 300);
      }
      if (val >= 1900) {
        this.qImgStyle = "width:100%;opacity:100";
        setTimeout(() => {
          this.qTextStyle = "margin-top:290px;opacity:100";
        }, 300);
      }
      if (val >= 3200) {
        this.evalTextStyle = "margin-top:0;opacity:100";
      }
      if (val >= 4300) {
        this.inputStyle = "margin-top:0;opacity:100";
      }
    },
  },
  created() {
    setInterval(this.top, 500);
    if (this.$route.path !== "/") {
      let a = this.$route.path.slice(1);
      this.go(a);
    }
  },
  mounted() {
    // 监听滚动条事件
    window.addEventListener("scroll", this.menu);
    window.addEventListener("scroll", this.handleScrollTop, true);

    let text = document.getElementById("s_ul");
    let len = text.children.length;
    let liWidth = 1200;

    // 获取复制第一个li节点
    let firsts = text.firstChild.cloneNode(true);
    // 获取复制最后一个li节点
    let lasts = text.lastChild.cloneNode(true);
    // 开头插入最后一个li的数据
    text.insertBefore(lasts, text.firstChild);
    text.style.left = "-" + liWidth + "px";
    // 最后插入第一个li的数据
    text.appendChild(firsts);
    // 设置轮播长度为所有li长度之和
    text.style.width = liWidth * (len + 2) + "px";
  },
  methods: {
    // 点击轮播左按钮
    btnLeft() {
      let text = document.getElementById("s_ul");
      let len = text.children.length;
      let index = this.Num;
      let liWidth = 1200;
      index--;
      this.Num = index;
      if (index < 1) {
        index = len - 1;
        this.Num = index;
        setTimeout(function () {
          text.style.transition = "none";
          text.style.left = "-" + index * liWidth + "px";
        }, 600);
      } else {
        text.style.left = "-" + index * liWidth + "px";
        text.style.transition = "0.6s";
      }
    },
    // 点击轮播右按钮
    btnRight() {
      let text = document.getElementById("s_ul");
      let len = text.children.length;
      let index = this.Num;
      let liWidth = 1200;
      index++;
      this.Num = index;
      text.style.left = "-" + index * liWidth + "px";
      text.style.transition = "0.6s";
      if (index >= len) {
        index = 2;
        this.Num = index;
        setTimeout(function () {
          text.style.transition = "none";
          text.style.left = "-" + index * liWidth + "px";
        }, 600);
      }
    },
    aa() {
      // this.dis = 'opacity:0'
      this.dis = "";
    },
    // 点击提交
    sub() {
      if (
        this.Name !== "" &&
        this.Email !== "" &&
        this.Phone !== "" &&
        this.Service !== ""
      ) {
        this.res = "success";
      } else {
        this.res = "Please fill in the complete information.";
      }
      this.dis = "opacity:100";
      setTimeout(() => {
        this.dis = "opacity:0";
      }, 3000);
    },
    // 回到顶部
    handleScrollTop() {
      let scrolltop = this.scroll;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // 获取滚动条位置
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    // 点击导航页面转移到该导航的div
    go(page, e, index) {
      let a = "";
      if (page) {
        a = "#" + page;
      }
      window.location.hash = a;
      if (e !== undefined) {
        e.target.style.color = "#ff7032";
      }
      let nav = document.getElementById("nav");

      if (nav !== null) {
        for (let i = 0; i < nav.children.length; i++) {
          if (i !== index) {
            nav.children[i].style.color = "";
          }
        }
      }
    },
    // 首页首次显示动态特效
    top() {
      this.text_top = "margin-top:290px;opacity:100%";
    },
    // 打开导航栏
    open() {
      this.openOrClose = "left:0";
    },
    // 关闭导航栏
    Close() {
      this.openOrClose = "left:-280px";
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  height: 100%;
  .heard {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 0 30px 40px;
    background: #fff;
    z-index: 99;
    .homes {
      width: 44px;
      padding-right: 226px;
      cursor: pointer;
    }
    .logo {
      width: 260px;
    }
  }
  .navigation {
    position: fixed;
    width: 280px;
    height: 100%;
    background: #282e3c;
    color: #fff;
    z-index: 100;
    transition: left 0.5s;
    -webkit-transition: left 0.5s; /* Safari */
    img {
      cursor: pointer;
      width: 44px;
      margin-left: 30px;
      margin-top: 30px;
    }
    ul {
      margin-top: 40px;
    }
    li {
      width: 200px;
      text-align: left;
      margin-bottom: 35px;
      margin-left: 30px;
      list-style: none;
      font-size: 20px;
      cursor: pointer;
    }
    li:hover {
      color: #ff7032;
    }
  }

  .homePage {
    width: 100%;
    padding-top: 104px;
    .h_left {
      width: 50%;
      .text {
        position: absolute;
        width: 550px;
        margin: 1000px 0 0 300px;
        opacity: 0;
        transition: margin-top 1s, opacity 1s;
        -webkit-transition: margin-top 1s, opacity 1s; /* Safari */
        .bold {
          font-weight: bold;
          font-size: 48px;
          color: #282e3c;
          display: block;
          margin-bottom: 60px;
        }
        .min_size {
          color: #979797;
          font-size: 18px;
        }
      }
      .btn {
        margin-top: 130px;
        span {
          display: block;
          width: 240px;
          height: 100px;
          text-align: center;
          background: #ff6f32;
          color: #fff;
          font-size: 24px;
          line-height: 100px;
          border-radius: 5px;
          font-weight: bold;
          cursor: pointer;
        }
        span:hover {
          background: rgb(242, 82, 24);
        }
      }
    }
    .h_right {
      width: 50%;
      margin-left: 50%;
      img {
        width: 100%;
      }
    }
  }
  .realTiem {
    position: relative;
    width: 100%;
    min-height: 800px;
    margin-top: 150px;
    .r_left_img {
      position: absolute;
      width: 50%;
      right: 50%;
      top: 0;
      z-index: 1;
      text-align: center;

      img {
        width: 40%;
        opacity: 0;
        transition: width 1s, opacity 1s;
        -webkit-transition: width 1s, opacity 1s; /* Safari */
      }
    }
    .r_right_text {
      background: #282e3c;
      width: 70%;
      position: absolute;
      top: 50px;
      right: 0;
      z-index: 0;
      .r_text {
        width: 65%;
        min-height: 500px;
        float: right;
        .text {
          margin: auto;
          width: 550px;
          color: #fff;
          margin-top: 690px;
          opacity: 0;
          transition: margin-top 1s, opacity 1s;
          -webkit-transition: margin-top 1s, opacity 1s; /* Safari */
          float: left;
          h1 {
            font-size: 48px;
          }
          ul {
            margin-top: 60px;
            padding-bottom: 50px;
            li {
              list-style: none;
              margin-bottom: 40px;
              span {
                display: block;
                margin-bottom: 20px;
              }
              .title {
                font-size: 24px;
              }
              .span {
                font-size: 18px;
                color: #7f8591;
              }
            }
          }
        }
      }
    }
  }
  .quality {
    position: relative;
    width: 100%;
    height: 1211px;
    margin-top: 200px;
    .q_left {
      width: 50%;
      position: absolute;
      left: 0;
      .text {
        width: 550px;
        margin: auto;
        margin-top: 790px;
        opacity: 0;
        transition: margin-top 1s, opacity 1s;
        -webkit-transition: margin-top 1s, opacity 1s; /* Safari */
        span {
          display: block;
          margin-bottom: 150px;
        }
        .title {
          font-size: 48px;
          font-weight: bold;
          color: #282e3c;
        }
        .content {
          font-size: 18px;
          color: #979797;
        }
      }
    }
    .q_right {
      width: 50%;
      position: absolute;
      right: 0;
      text-align: center;
      img {
        width: 40%;
        opacity: 0;
        transition: width 1s, opacity 1s;
        -webkit-transition: width 1s, opacity 1s;
      }
    }
  }
  .evaluation {
    width: 100%;
    .topImg {
      width: 100%;
    }
    .content {
      background: #f8f7f5;
      padding: 0;
      margin-top: -4px;
      padding-top: 70px;
      width: 100%;
      .neirong {
        margin-top: 550px;
        opacity: 0;
        transition: margin-top 1s, opacity 1s;
        -webkit-transition: margin-top 1s, opacity 1s; /* Safari */
        .title {
          display: block;
          text-align: center;
          font-size: 24px;
        }
      }

      .titleImg {
        width: 85px;
        display: block;
        margin: auto;
        margin-top: 100px;
      }
      .sildeText {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        padding-bottom: 140px;
        img {
          width: 40px;
          cursor: pointer;
        }
        // img:hover {
        //   background: #fff;
        //   padding: 10px;
        //   border-radius: 5px;
        // }
        .s_text {
          width: 1200px;
          height: 100px;
          position: relative;
          overflow: hidden;
          ul {
            position: absolute;
            left: 0;
            right: 0;
            // transition: left 0.8s, right 0.8s;
            // -webkit-transition: left 0.8s, right 0.8s; /* Safari */
            li {
              width: 1200px;
              list-style: none;
              display: inline-block;
              text-align: center;
              float: left;
              .span {
                line-height: 100px;
              }
            }
          }
        }
      }
    }
  }
  .Contact {
    color: #fff;
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    .setInfo {
      width: 100%;
      background: #282e3c;
      margin-top: -6px;
      padding-top: 70px;
      .neirong {
        margin-top: 550px;
        opacity: 0;
        transition: margin-top 1s, opacity 1s;
        -webkit-transition: margin-top 1s, opacity 1s; /* Safari */
        .title {
          display: block;
          text-align: center;
          font-size: 24px;
        }
        .text {
          display: block;
          text-align: center;
          font-size: 18px;
          margin-top: 70px;
        }
        .sumbit {
          text-align: center;
          margin-top: 40px;
          color: #fff;

          input {
            width: 600px;
            height: 90px;
            background: #323845;
            // opacity: 5%;
            border: none;
            border-radius: 5px;
            margin: 40px 10px 0 10px;
            outline: medium;
            color: #fff;
            text-indent: 20px;
          }
          .tijiao {
            display: block;
            text-align: center;
            width: 1220px;
            height: 90px;
            border-radius: 5px;
            background: #ff6f32;
            line-height: 90px;
            margin: auto;
            margin-top: 60px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .tips {
        position: fixed;
        top: 40%;
        left: 0;
        right: 0;
        color: #808080;
        text-align: center;
        span {
          display: inline-block;
          width: 90px;
          height: 30px;
          padding: 20px 40px;
          background: #fff;
          border-radius: 10px;
          margin: auto;
          opacity: 0;
          transition: opacity 0.5s;
          -webkit-transition: opacity 0.5s; /* Safari */
        }
      }
      hr {
        margin: auto;
        margin-top: 100px;
        width: 1320px;
        opacity: 10%;
      }
    }
  }
  .bottom {
    background: #282e3c;
    padding-bottom: 80px;
    .logo {
      width: 16%;
      margin-left: 16%;
      margin-top: 80px;
    }
    .btn {
      width: 4%;
      margin-left: 10%;
    }
    span {
      display: inline-block;
      width: 50%;
      font-size: 18px;
      color: #fff;
      text-align: right;
      line-height: 40px;
    }
  }
}
</style>
