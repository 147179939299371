import Vue from 'vue'
import Router from 'vue-router'
// import page from '@/components/page'
import index from '@/components/index'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index,
      redirect: '/home',
      children: [
        {
          path: 'home',
          name: 'home',
          component: index
        },
        {
          path: 'interactive',
          name: 'interactive',
          component: index
        },
        {
          path: 'quality',
          name: 'quality',
          component: index
        },
        {
          path: 'evaluation',
          name: 'evaluation',
          component: index
        },
        {
          path: 'contact',
          name: 'contact',
          component: index
        }
      ]
    }
  ]
})
